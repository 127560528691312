@font-face {
  font-family: 'KatwijkMono';
  font-weight: bold;
  src: local('KatwijkMono'), url(./fonts/KatwijkMono-Black.otf);
}

@font-face {
  font-family: 'KatwijkMono';
  src: local('KatwijkMono'),
    url(./fonts/KatwijkMono-Regular.otf) format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, 'KatwijkMono', BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'KatwijkMono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
